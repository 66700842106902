import React from 'react';
import Man1 from 'images/men/Marc.svg';
import Man2 from 'images/men/Fabien.svg';
import Man3 from 'images/men/Richard.svg';
import config from 'utils/siteConfig';
import { remiOnly } from 'components/common/data/doctors';

import lessonsData from './programData.json';

export const scientistData = {
  doctor: remiOnly,
  title: 'Le directeur scientifique de la thérapie Sommeil',
  subtitle: '',
  body: 'Docteur en médecine généraliste, spécialisé dans les troubles de sommeil depuis plus de 10 ans, le Dr Rémi Lombard est basé à la Polyclinique Vauban de Valenciennes au sein du service de "somnologie". Il accompagne au quotidien des personnes insomniaques ou qui ont des difficultés à avoir un sommeil de qualité. Conscient des enjeux énormes du sommeil et de son impact sur le bien-être quotidien, il a décidé de se spécialiser et de mettre en place une thérapie cognitivo-comportementale efficace sur plus de 85% des patients testés. Chaleureux, il adopte une approche familière et personnalisée pour chacun de ses patients. Approche qu\'il a reproduit dans le Programme Sommeil.',
};

export const metaData = {
  title: 'Améliorer ses nuits avec le programme SOMMEIL | Charles.co',
  description: 'Améliorez vos nuits grâce au programme SOMMEIL développé par des médecins.',
};

export const topSection = {
  h1: 'Retrouvez le sommeil naturellement en 6 semaines',
  programSlug: 'sommeil-program',
  ctaLabel: 'Tester gratuitement la première étape',
  borderIcon: 'icon-sommeil.svg',
  subtitle: (
    <div className="pathology-subtitle">
      Insomnies, endormissements difficiles, nuits non réparatrices, fatigue chronique, sommeil léger...
      <br />
      Découvrez la méthode des somnologues pour en finir avec ces problèmes en 6 semaines, sans somnifère.
    </div>
  ),
  video: {
    titleDisplay: false,
    filename: 'videos/thumbnails/thumbnail-sleep-program.jpg',
    alt: 'Trailer Programme Sommeil',
    title: 'Trailer Programme Sommeil',
    video:
      'https://cdn.jwplayer.com/players/WWMlojzP-KdT3I1iC.html',
  },
  reinsurance: [
    {
      text: 'Efficacité prouvée : 85% des patients constatent une amélioration, sans somnifère',
      icon: 'success.svg',
      alt: 'thumbs-up-sommeil',
      title: 'thumbs up sommeil',
    },
    {
      text: '6 semaines de thérapie en ligne sans engagement',
      icon: 'cam-icon.svg',
      alt: 'icone-camera',
      title: 'icone camera',
    },
    {
      text:
        '6 séances personnalisées en fonction de l’analyse de votre sommeil',
      icon: 'calendar.svg',
      alt: 'agenda-sommeil',
      title: 'agenda sommeil',
    },
    {
      text:
        'Méthode simple (10 minutes/jour)',
      icon: 'clock.svg',
      alt: 'docteur-qui-parle',
      title: 'docteur qui parle',
    },
    {
      text:
        'Hotline téléphonique dédiée tout au long du programme',
      icon: 'doctor-talking.svg',
      alt: 'docteur-qui-parle',
      title: 'docteur qui parle',
    },
  ],
};

export const about = {
  h2: 'Pourquoi suivre le Programme Sommeil ?',
  accordion: [
    {
      title: 'Que vais-je trouver dans le Programme Sommeil ?',
      text:
        "Le Programme Sommeil est une thérapie cognitivo-comportementale qui se répartit en 6 étapes d'une semaine. Tous les 7 jours, vous recevrez une vidéo comprenant trois parties :  une partie théorique permettant de comprendre le mécanisme de votre sommeil, des conseils et exercices pratiques, ainsi qu'une séance d'aide à l'endormissement à appliquer chez vous. Chaque vidéo est accompagnée de sa fiche récapitulative et d'un accompagnement personnalisé sur demande.",
    },
    {
      title: "À qui s'adresse le Programme Sommeil ?",
      text:
        "Le Programme Sommeil s'adresse à deux catégories de personnes : celles qui ont un ou plusieurs troubles du sommeil (insomnie, sommeil de mauvaise qualité) et celles qui souhaitent maximiser leur qualité de sommeil au quotidien et donc, leur état de bien-être général.\n Notez que vous pouvez entamer le Programme Sommeil même si vous êtes déjà suivi par un spécialiste du sommeil, vous pouvez en discuter avec avec lui ou elle afin de le mener ensemble. Que vous preniez ou non des somnifères, vous pouvez mener à bien le programme.",
    },
    {
      title: 'Y a-t-il un suivi personnalisé pendant la durée du programme ?',
      text:
        "Le Programme Sommeil est une thérapie 100% en ligne. Mais cela ne signifie pas que vous serez seul(e) face à votre écran. D'abord, nous avons tout mis en place pour que vous receviez un soutien régulier par e-mail et par téléphone. Également, grâce à votre fiche récapitulative, vous pouvez accéder rapidement aux informations sans revenir sur la vidéo.\n Et finalement, il vous suffit de décrocher votre téléphone ou d'envoyer un mail dès que vous sentez que vous avez besoin d'aide pour passer une étape ou que vous avez une question, nos coachs spécialement formés sont disponibles du lundi au vendredi de 9h30 à 19h.",
    },
  ],
  definition: (
    <div>
      Mauvaise qualité de sommeil, difficultés d'endormissement, réveils
      fréquents ou difficultés pour s'endormir à nouveau après un réveil. Ces
      problèmes aux conséquences graves touchent 20 millions de français et on
      estime que
      {' '}
      <strong>moins de 10% d’entre eux se traitent.</strong>
    </div>
  ),
};

export const whyItWorks = {
  title: 'Pourquoi est-ce efficace ?',
  text: "",
  list: [
    {
      fullName: 'Comprendre pour agir',
      description: 'Comprenez votre insomnie et analysez là avec votre agenda de sommeil.',
      pictures: {
        regular: 'new-images/coffee-man.jpeg',
      },
    },
    {
      fullName: 'Modifier profondément les habitudes',
      description: "Intégrez de nouvelles hygiènes de vie facilitant un sommeil de qualité.",
      pictures: {
        regular: 'new-images/sleeping-man.jpeg',
      },
    },
    {
      fullName: 'Maîtriser des techniques efficaces',
      description: "Apprenez et appliquez des techniques comportementales et cognitives pour reprogrammer votre sommeil.",
      pictures: {
        regular: 'doctors/remi-2.jpeg',
      },
    },
  ],
};

export const testimonies = {
  title: 'Nos patients témoignent',
  subtitle: 'Et ce sont eux qui en parlent le mieux',
  content: [
    {
      text:
        "\"Je me suis laissé tenter par le programme en me disant que cela ne pouvait pas me faire de mal. Insomniaque depuis plus de 15 ans, j'ai à peu près tout testé avec des résultats plus ou moins décevants, et aucun véritablement durable. Je n'ai pas été déçu, l'étape du coucher n'est plus un stress permanent, mes nuits ne se sont pas vraiment rallongées, mais je ne me réveille plus et ça change tout.\"",
      info: 'Benoît, 43 ans',
    },
    {
      text:
        '"Quand on se lance dans la thérapie, c\'est vrai qu\'il faut être motivé parce que ça demande des efforts et surtout de la régularité. C\'était un peu difficile pour moi au début, mais Marion m\'a aidé, je me suis accroché et clairement mes nuits n\'ont plus rien à voir. Je continue le programme de sevrage des somnifères, mais on touche au but. Merci du fond du coeur"',
      info: 'Jarvis, 31 ans',
    },
    {
      text:
        '"Ce sont mes parents qui m\'ont payé le programme car je suis encore étudiant. J\'avais du mal à m\'endormir avec le stress, l\'angoisse de louper mes exams et tout ça.. Je n\'avais pas envie de prendre des somnifères, et en même temps je ne tenais plus le rythme. Ça m\'a vraiment aidé, je le recommande aux gens qui me parlent de leur problèmes pour dormir."',
      info: 'Andrew, 22 ans',
    },
    /*    {
          text:
            "Depuis que je suis arrivé à Paris pour mes études, j’étais crevé et je dormais mal. Je ne regrette pas d'avoir consulté. J’ai suivi la Thérapie Sommeil et rapidement je me suis senti mieux. Comme j’avais encore un peu de mal à me coucher je me suis équipé sur conseil du médecin d'une lampe de luminothérapie et maintenant tout est bon.",
          info: "Lou, 22 ans",
        }, */
  ],
  percent:
    'Plus de 85% des personnes constatent une amélioration sensible et durable de leur qualité de sommeil suite à leur prise en charge.',
};

export const sections = {
  title: ' ',
  videoList: [
    {
      filename: 'videos/thumbnails/gilbert1.png',
      alt: '',
      title: "Comment ça marche l'érection ?",
      video:
        'https://www.youtube.com/embed/RC35pfov2vA?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'RC35pfov2vA',
        title: "Comment ça marche l'érection ?",
        description: null,
        duration: '4:38',
        uploadDate: new Date(2019, 3, 16).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert3-1.png',
      alt: '',
      title: 'Comment reconnaître une dysfonction érectile ?',
      video:
        'https://www.youtube.com/embed/fWg7nNPmIRU?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'fWg7nNPmIRU',
        title: 'Comment reconnaître une dysfonction érectile ?',
        description: null,
        duration: '3:24',
        uploadDate: new Date(2019, 3, 11).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert6-1.png',
      alt: '',
      title:
        "Comment fonctionnent les médicaments contre les troubles de l'érection ?",
      video:
        'https://www.youtube.com/embed/i8cGg1J2w_U?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'i8cGg1J2w_U',
        title:
          "Comment fonctionnent les médicaments contre les troubles de l'érection ?",
        description: null,
        duration: '6:27',
        uploadDate: new Date(2019, 5, 7).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert8-1.png',
      alt: '',
      title: "Influence de certains médicaments sur l'érection",
      video:
        'https://www.youtube.com/embed/ysJ7Y6_5hMU?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'ysJ7Y6_5hMU',
        title: "Influence de certains médicaments sur l'érection",
        description: null,
        duration: '4:08',
        uploadDate: new Date(2019, 5, 24).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert7-1.png',
      alt: '',
      title: "Influence de l'état psychologique sur l'érection",
      video:
        'https://www.youtube.com/embed/ZggHQ0GmZbo?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'ZggHQ0GmZbo',
        title: "Influence de l'état psychologique sur l'érection",
        description: null,
        duration: '4:16',
        uploadDate: new Date(2019, 6, 21).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert10-1.png',
      alt: '',
      title: 'Quand consulter un médecin sexologue ?',
      video:
        'https://www.youtube.com/embed/5O2b_qxrEho?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: '5O2b_qxrEho',
        title: 'Quand consulter un médecin sexologue ?',
        description: null,
        duration: '2:25',
        uploadDate: new Date(2019, 5, 3).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert12-1.png',
      alt: '',
      title:
        "Y a-t-il des traitements contre les troubles de l'érection plus efficaces que d'autres ?",
      video:
        'https://www.youtube.com/embed/mn0M8gpHV1M?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'mn0M8gpHV1M',
        title:
          "Y a-t-il des traitements contre les troubles de l'érection plus efficaces que d'autres ?",
        description: null,
        duration: '4:01',
        uploadDate: new Date(2019, 5, 17).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert13.png',
      alt: '',
      title: 'Pourquoi a-t-on des érections le matin ?',
      video:
        'https://www.youtube.com/embed/hbv21fdGkhM?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'hbv21fdGkhM',
        title: 'Pourquoi a-t-on des érections le matin ?',
        description: null,
        duration: '5:30',
        uploadDate: new Date(2019, 5, 17).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert14-1.png',
      alt: '',
      title: "L'évolution des troubles de l'érection de 18 à 78 ans et plus",
      video:
        'https://www.youtube.com/embed/gakTq3seYuU?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'gakTq3seYuU',
        title: "L'évolution des troubles de l'érection de 18 à 78 ans et plus",
        description: null,
        duration: '7:11',
        uploadDate: new Date(2019, 5, 7).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert16.png',
      alt: '',
      title: "Les médicaments de l'érection sont-ils dangereux pour la santé ?",
      video:
        'https://www.youtube.com/embed/d3aWFE8kVQw?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'd3aWFE8kVQw',
        title:
          "Les médicaments de l'érection sont-ils dangereux pour la santé ?",
        description: null,
        duration: '6:36',
        uploadDate: new Date(2019, 5, 14).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert17.png',
      alt: '',
      title: "Quelles sont les causes d'une baisse de libido ?",
      video:
        'https://www.youtube.com/embed/Q1yd6CywKbQ?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'Q1yd6CywKbQ',
        title: "Quelles sont les causes d'une baisse de libido ?",
        description: null,
        duration: '4:51',
        uploadDate: new Date(2019, 5, 3).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert18-2.png',
      alt: '',
      title: "10 choses à savoir sur les problèmes de l'érection",
      video:
        'https://www.youtube.com/embed/kCZOukKH9RU?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'kCZOukKH9RU',
        title: "10 choses à savoir sur les problèmes de l'érection",
        description: null,
        duration: '2:57',
        uploadDate: new Date(2019, 4, 4).toISOString(),
      },
    },
  ],
  faq: [
    {
      title: ' Quels sont les différents types de troubles du sommeil ?',
      text: (
        <div>
          L’Insomnie se manifeste par une mauvaise qualité de sommeil, des
          difficultés d'endormissement, des réveils fréquents ou des difficultés
          pour s'endormir à nouveau après un réveil et/ou un réveil matinal
          précoce. Elle concerne 20% des adultes et elle est sévère dans 10% des
          cas. Elle est chronique si elle est présente au moins 3 nuits par
          semaine depuis plus de 3 mois. La somnolence se caractérise par un
          fort besoin de dormir. Elle s'accompagne d'une réduction de la
          vigilance et d'une baisse du tonus musculaire comme de la fréquence du
          rythme cardiaque. Elle est constatée chez 15 à 20% des adultes L’apnée
          du sommeil est un arrêt de la respiration d’au moins 10 secondes
          pendant le sommeil. Elle se caractérise souvent par des ronflements,
          un sommeil agité, des troubles de la respiration nocturne, de la
          somnolence ou de la fatigue.Elle touche 10% des femmes et 15% des
          hommes.,
        </div>
      ),
    },
    {
      title: ' Comment résoudre ses problèmes de sommeil ?',
      text: (
        <div>
          Sur Charles.co vous pouvez consulter à distance avec un médecin
          spécialiste du sommeil qui fera votre bilan de sommeil. Il évaluera
          votre situation de sommeil, de santé, vos habitudes de vie et vous
          donnera un plan de traitement personnalisé et adapté à votre
          situation. Ce plan de traitement pourra comporter le programme
          Sommeil, une thérapie en ligne personnalisée visant à changer vos
          habitudes de sommeil ou d’autres éléments améliorant votre sommeil, et
          évitera au maximum l’utilisation de somnifères.
        </div>
      ),
    },
    {
      title: ' Pourquoi faut il éviter les somnifères ?',
      text: (
        <div>
          "Les somnifères les plus répandus (benzodiazépines) ont des propriétés
          sédatives, anxiolytiques, amnésiantes, myorelaxantes,
          anticonvulsivantes et orexigènes (stimulation de l'appétit) Ils
          entraînent certes un endormissement plus rapide, une réduction du
          nombre et de la durée des éveils nocturnes et une augmentation moyenne
          du temps de sommeil de 30 à 45 min. Mais ils sont dangereux : - ils
          diminuent le sommeil lent profond et dans une moindre mesure le
          sommeil paradoxal, au profit du sommeil lent léger moins réparateur. -
          ils entraînent une dépendance et un accoutumance en 4 semaines. - ils
          ont des effets résiduels le lendemain : somnolence, une diminution des
          performances, des problèmes de coordination motrice et des effets sur
          la mémoire indéniable - ils favorisent l’apnée du sommeil par
          l’activité myorelaxante. - ils donnent une fausse impression de bien
          dormir car on oublie les réveils (propriété amnésiante) - ils
          multiplieraient par deux le risque de mortalité prématurée et
          augmenterait de 35% le risque de développer un cancer - les personnes
          consommant des somnifères présenteraient également un risque plus
          élevé d’environ 50% de présenter une maladie d’alzheimer. "
        </div>
      ),
    },
    {
      title:
        ' Qu’est ce que le programme SOMMEIL, la thérapie du sommeil de Charles.co ?',
      text: (
        <div>
          Le Programme SOMMEIL est une thérapie cognitivo comportementale en
          ligne créée par les médecins somnologues de Charles. Elle se déroule
          sur 6 semaines et améliore significativement et durablement le sommeil
          des patients dans plus de 85% des cas sans aucun recours aux
          somnifères. Lors de votre consultation, le médecin somnologue pourra
          vous recommander cette thérapie personnalisée à la suite de l’examen
          de votre dossier et de l’échange avec vous.
        </div>
      ),
    },
    {
      title: ' Comment se déroule une téléconsultation sur Charles ?',
      text:
        ' En quelques clics, il suffit de remplir un questionnaire médical sécurisé, de choisir son médecin, et de confirmer la date et l’horaire de la téléconsultation. 3 modes de téléconsultation s’offrent au patient : par messagerie sécurisée, appel téléphonique ou par visioconférence. Le médecin établit un diagnostic, accessible à tout moment dans un espace sécurisé. ',
    },
    {
      title: ' Quels sont les avantages de la téléconsultation ?',
      text: (
        'Les avantages de la téléconsultation chez Charles.co sont : l’accès rapide à un médecin diplômé en moins de 24h ; la possibilité de recevoir un traitement à domicile ; un prix de consultation (' +
        config.consultationPrice +
        ' euros) en dessous de la moyenne constatée en cabinet ; l’accès à un espace patient sécurisé, avec le compte-rendu médical de la consultation.'
      ),
    },
  ],
};

export default {
  sections,
  topSection,
  testimonies,
  about,
  whyItWorks,
  scientistData,
  metaData,
  lessonsData,
};
